import React from 'react';
import { graphql, Link } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Button from '../components/Button';
import Layout from 'src/layouts/Layout';
import './career.scss';

const career = (props) => {
  const jobs = props?.data?.contentfulJobList?.jobs || [];

  return (
    <Layout>
      <div className='career-page-container'>
        <Fade bottom>
          <h2 className='mahh-section-title-border text-5xl text-white'>
            CAREERS
          </h2>
        </Fade>
        {/* Empty state */}
        {jobs.length === 0 && (
          <h3 className='w-full text-xl text-center text-white pt-8'>
            There are currently no vacancies available{' '}
            <span role='img' aria-label='checkmark'>
              😊
            </span>
          </h3>
        )}
        {/* List of Jobs */}
        <div className='job-cards-container'>
          {jobs?.map((job, index) => {
            return (
              <Fade bottom key={index}>
                <div className='job-card cursor-pointer'>
                  <h5 className='job-title'>{job?.jobTitle}</h5>
                  <p className='job-main-description'>{job?.mainDescription}</p>
                  <Link to={`/career/${job?.slug}`}>
                    <Button style={{ fontSize: '12px' }}>APPLY NOW</Button>
                  </Link>
                </div>
              </Fade>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query CareerPageQuery {
    contentfulJobList {
      jobs {
        jobTitle
        mainDescription
        slug
      }
    }
  }
`;

export default career;
